import { AttributesCategoryGroup, IdTypeSelector, MemberStatus, MemberTypes, PreferredContactMethods } from "../Data";
import { toTitleCase } from "./StringUtils";
import { City, State } from "country-state-city";

const equalAndNotEqualSelectOperators = ["select_equals", "select_not_equals"];

const identificationFilters = (() => {
    if (!IdTypeSelector) return {};
    return IdTypeSelector?.reduce((result, idType) => {
        result[idType.key] = {
            label: idType.label,
            value: idType.value,
            type: "string",
        };
        return result;
    }, {});
});

const createField = ({ label, type, operators = null, fieldSettings = null }) => ({
    label,
    type,
    ...(operators ? { operators } : {}),
    ...(fieldSettings ? { fieldSettings } : {}),
});


const generateAttributes = ({ attributes, merchantLocations, tiers, affinityGroups, selectedRegion }) => {
    const registeredLocations =
        Object.values(merchantLocations)
            .reduce((result, item) => {
                result.push(Object.values(item));
                return result;
            }, [])
            .flat(1) || [];

    return {
        ...attributes,
        ...(attributes?.hasOwnProperty("status")
            ? {
                status: createField({
                    label: "Status",
                    type: "select",
                    operators: equalAndNotEqualSelectOperators,
                    fieldSettings: Object.values(MemberStatus)
                        .filter((s) => s !== MemberStatus.ARCHIVED)
                        .map((mS) => ({
                            title: toTitleCase(mS),
                            value: mS,
                        })),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("type")
            ? {
                type: createField({
                    label: "Type",
                    type: "select",
                    operators: equalAndNotEqualSelectOperators,
                    fieldSettings: Object.values(MemberTypes)
                        .filter((t) => t !== MemberTypes.CHARITY)
                        .map((mT) => ({
                            title: toTitleCase(mT),
                            value: mT,
                        })),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("tier.tierId")
            ? {
                "tier.tierId": createField({
                    label: "Tier",
                    type: "select",
                    fieldSettings: tiers.map((tier) => ({
                        title: tier?.name || "~ unknown",
                        value: tier?._id || "~ unknown",
                    })),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("affinityGroup.affinityGroupId")
            ? {
                "affinityGroup.affinityGroupId": createField({
                    label: "Affinity Group",
                    type: "select",
                    fieldSettings: affinityGroups.map((affinityGroup) => ({
                        title: affinityGroup?.name || "~ unknown",
                        value: affinityGroup?._id || "~ unknown",
                    })),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("merchantLocationId")
            ? {
                merchantLocationId: createField({
                    label: "Registered Location",
                    type: "select",
                    fieldSettings: registeredLocations.map((location) => ({
                        title: location?.locationName || "~ unknown",
                        value: location?._id || "~ unknown",
                    })),
                }),
            }
            : {}),

        ...(attributes?.hasOwnProperty("notificationPreference.allowPromotionalNotifications")
            ? {
                "notificationPreference.allowPromotionalNotifications": createField({
                    label: "Allow Promotional Notifications",
                    type: "string",
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("notificationPreference.preferredChannel")
            ? {
                "notificationPreference.preferredChannel": createField({
                    label: "Preferred Channel",
                    type: "select",
                    fieldSettings: Object.values(PreferredContactMethods).map((pCM) => ({
                        title: toTitleCase(pCM),
                        value: pCM,
                    })),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("additionalPhoneNumbers")
            ? {
                additionalPhoneNumbers: createField({
                    label: "Additional Phone Numbers",
                    type: "string",
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("pointsToExpire")
            ? {
                pointsToExpire: createField({
                    label: "Points To Expire",
                    type: "number",
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("pointsExpireOn")
            ? {
                pointsExpireOn: createField({
                    label: "Points Expire On",
                    type: "date",
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("residentialAddress.city")
            ? {
                "residentialAddress.city": createField({
                    label: "City",
                    type: "select",
                    operators: equalAndNotEqualSelectOperators,
                    fieldSettings: City.getCitiesOfCountry(
                        selectedRegion?.defaultCountryISO2Code,
                    )
                        .filter((city) => !city.name.toLowerCase().endsWith("district"))
                        .map((fCity) => ({
                            title: toTitleCase(fCity.name),
                            value: toTitleCase(fCity.name),
                        })),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("residentialAddress.stateOrProvince")
            ? {
                "residentialAddress.stateOrProvince": createField({
                    label: "State/Province/Parity/District",
                    type: "select",
                    operators: equalAndNotEqualSelectOperators,
                    fieldSettings: State.getStatesOfCountry(
                        selectedRegion?.defaultCountryISO2Code,
                    ).map((state) => {
                        const cleanedStateName = state.name
                            .toLowerCase()
                            .replace("district", "")
                            .replace("province", "");

                        return {
                            title: toTitleCase(cleanedStateName),
                            value: toTitleCase(cleanedStateName),
                        };
                    }),
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("portalMetadata.userId")
            ? {
                "portalMetadata.userId": createField({
                    label: "Portal User Id",
                    type: "string",
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("portalMetadata.username")
            ? {
                "portalMetadata.username": createField({
                    label: "Portal Username",
                    type: "string",
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("productName")
            ? {
                "productName": createField({
                    label: "Product Name",
                    type: "string",
                }),
            }
            : {}),
            ...(attributes?.hasOwnProperty("productId")
            ? {
                "productId": createField({
                    label: "Product Id",
                    type: "string",
                }),
            }
            : {}),
            ...(attributes?.hasOwnProperty("transactionOn")
            ? {
                "transactionOn": createField({
                    label: "Transaction On",
                    type: "date",
                }),
            }
            : {}),
            ...(attributes?.hasOwnProperty("productCategory")
            ? {
                "productCategory": createField({
                    label: "Product Category",
                    type: "array",
        
                }),
            }
            : {}),
        ...(attributes?.hasOwnProperty("portalMetadata.lastAccessedOn")
            ? {
                "portalMetadata.lastAccessedOn": createField({
                    label: "Portal Last Accessed On",
                    type: "date",
                }),
            }
            : {}),
    };
};

const buildFilterAttributes = (attributesCategoryGroup) => ({
                                                                attributes,
                                                                merchantLocations,
                                                                tiers,
                                                                affinityGroups,
                                                                selectedRegion,
                                                            }) => (
    {
        ...(attributesCategoryGroup === AttributesCategoryGroup.CONTACT_ATTRIBUTES ? { ...identificationFilters } : {}),
        ...generateAttributes({ attributes, merchantLocations, tiers, affinityGroups, selectedRegion }),
    }
);


export {
    buildFilterAttributes,
};

import React, {
    useReducer,
    useEffect,
    useCallback,
    useContext,
    useMemo,
} from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { UserContext } from "./userContext";
import {
    RewardStatus,
    RewardSubType,
    AccessPermissionModules,
    AccessPermissionModuleNames,
    JobTypesStatus,
    UserTypes,
    UserStatus,
    MerchantStatus,
    MerchantLocationStatusObj, AttributesCategoryGroup,
} from "Data";
import {
    getContactAttributes,
    getTiers,
    getAllMerchants,
    getAllMerchantLocations,
    getAllAffinityGroups,
    getRewards,
    getAllSubTransactionTypes,
    loadAllCharities,
    getJobTypes,
    getIdentityUsers,
    getAllSegments,
    getAllIdentityClients,
    getAllIdentityUsers,
} from "Services";
import { isEmptyObject } from "Utils";

const getClientOrIntegrationName = (data = null) => {
    if (data?.clientData) {
        return data.clientData?.clientId || "~ unknown";
    } else if (data?.userData) {
        let name = "";
        if (data.userData?.firstName || data.userData?.lastName) {
            if (data.userData?.firstName && data.userData?.lastName) {
                name = `${data.userData.firstName} ${data.userData.lastName}`;
            } else if (data.userData?.firstName && !data.userData?.lastName) {
                name = data.userData.firstName;
            } else {
                name = data.userData.lastName;
            }
        } else {
            name =
                data.userData?.username ||
                data.userData?._id ||
                data?._id ||
                "~ unknown";
        }

        return name;
    } else {
        return "~ unknown";
    }
};

const DataContext = React.createContext();

const ignoredAttributes = new Set([
    "created_by",
    "_modified_on",
    "_delete_protected",
    "_email_valid",
    "_mobile_number_valid",
    "notificationPreference",
    "customAttributes",
    "pointsToExpire",
    "transactions",
    "affinityGroup",
    "affinityGroupId",
    "pointsToExpire",
    "identifications"
]);

const initialState = {
    config: {
        rewards: {},
        points: {},
    },
    enableEdit: false,
    isLoading: true,
    isLoadingContactAttributes: true,
    contactAttributes: {},
    transactionAttributes:{},
    tags: [],
    isLoadingTiers: false,
    tiers: [],
    merchants: [],
    merchantLocations: {},
    affinityGroups: [],
    isLoadingAffinityGroups: false,
    isLoadingPartnerRewards: false,
    rewards: [],
    partnerRewardConfigs: {},
    isLoadingSubTransactionTypes: false,
    subTransactionTypes: [],
    isLoadingCharities: false,
    charities: [],
    isLoadingJobTypes: false,
    jobTypes: [], //TODO: [MLS-3371] Admin panel> Move job types to jobs scoped context as it is not required globally
    jsonSchemaForJobTypes: [],
    usersList: [],
    allUsersAndClients: [],
    isLoadingSegments: false,
    segments: [],
    areUsersUpdated: false,
    allMerchantsForDropdown: [],
    allMerchantLocationsForDropdown: [],
};

const DataContextActions = {
    SET_CONTACT_ATTRIBUTES: "setContactAttributes",
    SET_LOADING_CONTACT_ATTRIBUTES: "setLoadingContactAttributes",
    GET_TIERS: "getTiers",
    SET_LOADING_TIERS: "setLoadingTiers",
    SET_MERCHANTS: "setMerchants",
    SET_MERCHANT_LOCATIONS: "SET_MERCHANT_LOCATIONS",
    SET_AFFINITY_GROUPS: "setAffinityGroups",
    SET_IS_LOADING_AFFINITY_GROUPS: "setIsLoadingAffinityGroups",
    SET_IS_LOADING_PARTNER_REWARDS: "setIsLoadingPartnerRewards",
    SET_IS_LOADING_SUB_TRANSACTION_TYPES: "setIsLoadingSubTransactionTypes",
    SET_SUB_TRANSACTION_TYPES: "setSubTransactionTypes",
    SET_IS_LOADING_CHARITIES: "setIsLoadingCharities",
    SET_CHARITIES: "setCharities",
    SET_IS_LOADING_JOB_TYPES: "setIsLoadingJobTypes",
    SET_JOB_TYPES_DATA: "setJobTypesData",
    SET_PARTNER_REWARDS: "setPartnerRewards",
    SET_LOADING_REGIONS: "setLoadingRegions",
    SET_USERS: "setUsers",
    SET_ALL_USERS_AND_CLIENTS: "setAllUsersAndClients",
    SET_SEGMENTS: "setSegments",
    SET_IS_LOADING_SEGMENTS: "setIsLoadingSegments",
    SET_ARE_USERS_UPDATED: "setAreUsersUpdated",
    SET_FILTERS_RELATED_DATA: "setFiltersRelatedData",
};

const reducer = (state, action) => {
    switch (action.type) {
        case DataContextActions.GET_TIERS: {
            return {
                ...state,
                tiers: action.tiers,

                isLoadingTiers: false,
            };
        }
        case DataContextActions.SET_LOADING_TIERS: {
            return {
                ...state,
                isLoadingTiers: action.status,
            };
        }
        case DataContextActions.SET_LOADING_REGIONS: {
            return {
                ...state,
                isLoadingRegions: action.status,
            };
        }
        case DataContextActions.SET_LOADING_CONTACT_ATTRIBUTES: {
            return {
                ...state,
                isLoadingContactAttributes: action.status,
            };
        }
        case DataContextActions.SET_USERS: {
            return {
                ...state,
                usersList: action.users.items,
            };
        }
        case DataContextActions.SET_ALL_USERS_AND_CLIENTS: {
            return {
                ...state,
                allUsersAndClients: action.list,
            };
        }
        case DataContextActions.SET_CONTACT_ATTRIBUTES: {
            return {
                ...state,
                ...(action.contactAttributes?{contactAttributes: action.contactAttributes}:{}),
                ...(action.transactionAttributes?{transactionAttributes: action.transactionAttributes}:{}),
                tags: action.tags || [],
                isLoadingContactAttributes: false,
            };
        }
        case DataContextActions.SET_MERCHANTS: {
            return {
                ...state,
                merchants: action.merchants,
            };
        }
        case DataContextActions.SET_MERCHANT_LOCATIONS: {
            return {
                ...state,
                merchantLocations: action.locations,
            };
        }
        case DataContextActions.SET_IS_LOADING_AFFINITY_GROUPS: {
            return {
                ...state,
                isLoadingAffinityGroups: action.status,
            };
        }
        case DataContextActions.SET_AFFINITY_GROUPS: {
            return {
                ...state,
                isLoadingAffinityGroups: false,
                affinityGroups: action.groups,
            };
        }
        case DataContextActions.SET_IS_LOADING_PARTNER_REWARDS: {
            return {
                ...state,
                isLoadingPartnerRewards: action.status,
            };
        }
        case DataContextActions.SET_PARTNER_REWARDS: {
            return {
                ...state,
                rewards: action.rewards,
                partnerRewardConfigs: action.configs,
            };
        }
        case DataContextActions.SET_IS_LOADING_SUB_TRANSACTION_TYPES: {
            return {
                ...state,
                isLoadingSubTransactionTypes: action.status,
            };
        }
        case DataContextActions.SET_SUB_TRANSACTION_TYPES: {
            return {
                ...state,
                subTransactionTypes: action.subTransactionTypesRes,
            };
        }
        case DataContextActions.SET_IS_LOADING_CHARITIES: {
            return {
                ...state,
                isLoadingCharities: action.status,
            };
        }
        case DataContextActions.SET_CHARITIES: {
            return {
                ...state,
                charities: action.charitiesResponse,
            };
        }
        case DataContextActions.SET_IS_LOADING_JOB_TYPES: {
            return {
                ...state,
                isLoadingJobTypes: action.status,
            };
        }
        case DataContextActions.SET_JOB_TYPES_DATA: {
            return {
                ...state,
                jobTypes: action.activeJobTypes,
                jsonSchemaForJobTypes: action.jsonSchema,
            };
        }
        case DataContextActions.SET_SEGMENTS: {
            return {
                ...state,
                segments: action.segments,
            };
        }
        case DataContextActions.SET_IS_LOADING_SEGMENTS: {
            return {
                ...state,
                isLoadingSegments: action.status,
            };
        }
        case DataContextActions.SET_ARE_USERS_UPDATED: {
            return {
                ...state,
                areUsersUpdated: action.value,
            };
        }
        case DataContextActions.SET_FILTERS_RELATED_DATA: {
            return {
                ...state,
                [action.key]: action.data,
            };
        }
        default:
            return state;
    }
};

const flattenNestedAttributes =(attributes={},nestedAttributes=[],categoryGroup=AttributesCategoryGroup.CONTACT_ATTRIBUTES)=>{

    if(Object.keys(attributes).length===0){
        return attributes
    }

    else {
        let parentObject= attributes;
        let updatedParentObject={}
         nestedAttributes.forEach((attributeKey)=>{
             if (parentObject.hasOwnProperty(attributeKey) && Object.keys(parentObject[attributeKey]).length !== 0) {
                 Object.keys(parentObject[attributeKey]).forEach((attribute) => {
                     updatedParentObject = {
                         ...(
                             categoryGroup===AttributesCategoryGroup.CONTACT_ATTRIBUTES?{
                                 ...parentObject,
                                 ...updatedParentObject,
                                 [`${attributeKey||"-"}.${attribute||"-"}`]: parentObject[attributeKey][attribute]
                             }:{
                                 transactions:parentObject?.transactions||{},
                                 ...updatedParentObject,
                                 [`${attribute||"-"}`]: parentObject[attributeKey][attribute]
                             }),
                     };
                 });
             }
         });
        updatedParentObject = Object.entries(updatedParentObject).reduce((result, [key, value]) => {
             if (!ignoredAttributes.has(key)) {
                 result[key] = value;
             }
             return result;
         }, {})
         return updatedParentObject
    }

}

const getMerchantLocationsForFilters = ({
    merchants = [],
    merchantLocations = {},
}) => {
    const merchantsIdNameMap = merchants.reduce((result, merchant) => {
        result[merchant?._id] = merchant?.merchantName;
        return result;
    }, {});

    if (merchantLocations) {
        const merchantLocationsArray = Object.values(merchantLocations);

        return merchantLocationsArray
            .reduce((result, item) => {
                const locationsArr = Object.values(item || {}).map(
                    (location) => ({
                        ...location,
                        locationName:
                            location?.status !==
                            MerchantLocationStatusObj.ACTIVE
                                ? `${
                                    location?.locationName || "~ unknown"
                                } (Inactive)`
                                : location?.locationName || "~ unknown",
                        merchantName: merchantsIdNameMap[location?.merchantId],
                    })
                );
                result.push(locationsArr);

                return result;
            }, [])
            .flat();
    }
    return [];
};

const DataContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        isAuth,
        regionId,
        isRunningSystemInit,
        userProfileLoadCompleted,
        isAuthorizedForAction,
    } = useContext(UserContext);

    const setAreUsersUpdated = useCallback(
        (value = false) =>
            dispatch({
                type: DataContextActions.SET_ARE_USERS_UPDATED,
                value,
            }),
        [dispatch]
    );

    const loadIdUsers = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.USERS,
                    AccessPermissionModules[AccessPermissionModuleNames.USERS]
                        .actions.ListUsers
                )
            ) {
                try {
                    const contactResponse = await getIdentityUsers({
                        limit: 1000,
                        skip: 0,
                        regionId,
                    });
                    dispatch({
                        type: DataContextActions.SET_USERS,
                        users: contactResponse.data,
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load users!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            }
        },
        [isAuthorizedForAction]
    );

    const loadAllIdUsersAndClients = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.USERS,
                    AccessPermissionModules[AccessPermissionModuleNames.USERS]
                        .actions.ListUsers
                )
            ) {
                try {
                    const [allClientsResponse, allUsersResponse] =
                        await Promise.all([
                            getAllIdentityClients(regionId),
                            getAllIdentityUsers(regionId),
                        ]);
                    const allUsersAndClientsResponse = [
                        ...allClientsResponse,
                        ...allUsersResponse,
                    ];

                    // * Map data to show integrations and users.
                    const mappedAllUsersAndClients =
                        allUsersAndClientsResponse.map((aUAC) => {
                            const label = `${getClientOrIntegrationName(aUAC)}${
                                aUAC?.status !== UserStatus.ACTIVE
                                    ? "(" + aUAC.status + ")"
                                    : ""
                            }`;
                            let dataType = "";

                            switch (aUAC?.type) {
                                case UserTypes.CLIENT:
                                    dataType = "Integrations";
                                    break;
                                case UserTypes.USER:
                                    dataType = "Dashboard Users";
                                    break;
                                default:
                                    dataType = "Unknown Type";
                            }

                            return {
                                ...aUAC,
                                dataType,
                                label,
                                value: aUAC?._id,
                            };
                        });
                    dispatch({
                        type: DataContextActions.SET_ALL_USERS_AND_CLIENTS,
                        list: mappedAllUsersAndClients,
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load all users and clients!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            }
        },
        [isAuthorizedForAction]
    );

    const setTier = useCallback(
        (newTier) => {
            dispatch({ type: DataContextActions.GET_TIERS, config: newTier });
        },
        [dispatch]
    );

    const loadTiers = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.TIER,
                    AccessPermissionModules[AccessPermissionModuleNames.TIER]
                        .actions.ListTiers
                )
            ) {
                try {
                    dispatch({
                        type: DataContextActions.SET_LOADING_TIERS,
                        status: true,
                    });
                    const tiersResponse = await getTiers({ regionId });
                    dispatch({
                        type: DataContextActions.GET_TIERS,
                        tiers: tiersResponse.items || [],
                    });
                } catch (e) {
                    dispatch({
                        type: DataContextActions.SET_LOADING_TIERS,
                        status: false,
                    });
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load tiers!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            } else {
                dispatch({
                    type: DataContextActions.SET_LOADING_TIERS,
                    status: false,
                });
            }
        },
        [dispatch, isAuthorizedForAction]
    );

    const loadPartnerRewards = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.REWARD,
                    AccessPermissionModules[AccessPermissionModuleNames.REWARD]
                        .actions.ListRewards
                )
            ) {
                try {
                    const query = {
                        limit: 25,
                        skip: 0,
                        regionId,
                        status: RewardStatus.ENABLED,
                        subType: RewardSubType.PARTNER,
                    };

                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_PARTNER_REWARDS,
                        status: true,
                    });

                    const rewardsResponse = await getRewards(query);

                    const configsArray =
                        rewardsResponse?.items.map((reward) => ({
                            _id: reward._id,
                            ...reward?.partnerRewardMetadata,
                        })) || [];

                    const configs = configsArray.reduce((result, config) => {
                        result[config?.partnerRewardConfig] =
                            config?.partnerRewardConfig;
                        return result;
                    }, {});

                    dispatch({
                        type: DataContextActions.SET_PARTNER_REWARDS,
                        rewards: rewardsResponse?.items || [],
                        configs,
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load partner reward details!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                } finally {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_PARTNER_REWARDS,
                        status: false,
                    });
                }
            }
        },
        [isAuthorizedForAction, dispatch]
    );

    const loadContactAttributes = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.ATTRIBUTE,
                    AccessPermissionModules[
                        AccessPermissionModuleNames.ATTRIBUTE
                    ].actions.ListAttributes
                )
            ) {
                try {
                    dispatch({
                        type: DataContextActions.SET_LOADING_CONTACT_ATTRIBUTES,
                        status: true,
                    });
                    const contactAttributeResponse = await getContactAttributes(
                        regionId
                    );
                    let contactAttributes = contactAttributeResponse.items.length !== 0 ? contactAttributeResponse?.items[0]?.attributes : {};
                    let transactionAttributes = contactAttributeResponse.items.length !== 0 ? contactAttributeResponse?.items[0]?.attributes : {};
                    contactAttributes =  flattenNestedAttributes(contactAttributes, ["notificationPreference","customAttributes","pointsToExpire"],AttributesCategoryGroup.CONTACT_ATTRIBUTES);
                    transactionAttributes=flattenNestedAttributes(transactionAttributes, ["transactions"],AttributesCategoryGroup.TRANSACTION_ATTRIBUTES);
                    dispatch({
                        type: DataContextActions.SET_CONTACT_ATTRIBUTES,
                        contactAttributes,
                        transactionAttributes,
                        tags: contactAttributeResponse.items.length !== 0 ? contactAttributeResponse?.items[0]?.tags : [],
                    });
                } catch (e) {
                    dispatch({
                        type: DataContextActions.SET_LOADING_CONTACT_ATTRIBUTES,
                        status: false,
                    });
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load contact attributes!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            }
        },
        [isAuthorizedForAction, dispatch]
    );

    const requestLoadMerchantLocations = useCallback(
        async ({ regionId, merchantId = null }) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.LOCATION,
                    AccessPermissionModules[
                        AccessPermissionModuleNames.LOCATION
                    ].actions.ListLocations
                )
            ) {
                try {
                    const requestObject = { regionId };

                    if (merchantId) {
                        requestObject.merchantId = merchantId;
                    }
                    const locationsResponse = await getAllMerchantLocations(
                        requestObject
                    );

                    const locationsObject = locationsResponse.reduce(
                        (result, item) => {
                            if (!result[item.merchantId]) {
                                result[item.merchantId] = {};
                            }
                            result[item.merchantId][item._id] = {
                                ...item,
                                locationName: `${
                                    item.code ? item.code + " / " : ""
                                }${item.locationName}`,
                            };
                            return result;
                        },
                        {}
                    );
                    dispatch({
                        type: DataContextActions.SET_MERCHANT_LOCATIONS,
                        locations: locationsObject,
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load merchant locations!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            }
        },
        [isAuthorizedForAction, dispatch]
    );

    const loadMerchants = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.MERCHANT,
                    AccessPermissionModules[
                        AccessPermissionModuleNames.MERCHANT
                    ].actions.ListMerchants
                )
            ) {
                try {
                    const merchants = await getAllMerchants({ regionId });
                    dispatch({
                        type: DataContextActions.SET_MERCHANTS,
                        merchants,
                    });
                    // * Set merchants for filters.
                    dispatch({
                        type: DataContextActions.SET_FILTERS_RELATED_DATA,
                        key: "allMerchantsForDropdown",
                        data: merchants.map((merchant) => ({
                            ...merchant,
                            merchantName:
                                merchant?.status !== MerchantStatus.ACTIVE
                                    ? `${
                                        merchant?.merchantName || "~ unknown"
                                    } (Inactive)`
                                    : merchant?.merchantName || "~ unknown",
                        })),
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load merchants!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            }
        },
        [dispatch, isAuthorizedForAction]
    );

    const refreshMerchantsData = useCallback(
        (merchants) => {
            dispatch({
                type: DataContextActions.SET_MERCHANTS,
                merchants,
            });
        },
        [dispatch]
    );

    const loadAffinityGroups = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.AFFINITY_GROUPS,
                    AccessPermissionModules[
                        AccessPermissionModuleNames.AFFINITY_GROUPS
                    ].actions.ListAffinityGroups
                )
            ) {
                try {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_AFFINITY_GROUPS,
                        status: true,
                    });

                    const affinityGroupResponse = await getAllAffinityGroups({
                        regionId,
                    });
                    dispatch({
                        type: DataContextActions.SET_AFFINITY_GROUPS,
                        groups: affinityGroupResponse,
                        status: false,
                    });
                } catch (e) {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_AFFINITY_GROUPS,
                        status: false,
                    });
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load affinity groups!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                }
            }
        },
        [dispatch, isAuthorizedForAction]
    );

    const loadAllSubTransactions = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
                AccessPermissionModules[
                    AccessPermissionModuleNames.SUB_TRANSACTION_TYPE
                ].actions.ListSubTransactionTypes
            )
        ) {
            try {
                dispatch({
                    type: DataContextActions.SET_IS_LOADING_SUB_TRANSACTION_TYPES,
                    status: true,
                });

                const subTransactionTypesRes =
                    await getAllSubTransactionTypes();

                dispatch({
                    type: DataContextActions.SET_SUB_TRANSACTION_TYPES,
                    subTransactionTypesRes,
                });
            } catch (e) {
                console.error(e);
                toast.error(
                    <div>
                        Failed to load sub transaction types!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                        <br />
                        If the issue persists, please contact support.
                    </div>
                );
            } finally {
                dispatch({
                    type: DataContextActions.SET_IS_LOADING_SUB_TRANSACTION_TYPES,
                    status: false,
                });
            }
        }
    }, [isAuthorizedForAction, dispatch]);

    const loadCharities = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.CHARITIES,
                    AccessPermissionModules[
                        AccessPermissionModuleNames.CHARITIES
                    ].actions.ListCharities
                )
            ) {
                const payload = {
                    regionId,
                };
                try {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_CHARITIES,
                        status: true,
                    });

                    const charitiesResponse = await loadAllCharities(payload);

                    dispatch({
                        type: DataContextActions.SET_CHARITIES,
                        charitiesResponse,
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load charities!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                } finally {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_CHARITIES,
                        status: false,
                    });
                }
            }
        },
        [isAuthorizedForAction, dispatch]
    );

    const loadJobTypes = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.JOB_TYPES,
                AccessPermissionModules[AccessPermissionModuleNames.JOB_TYPES]
                    .actions.ListJobTypes
            )
        ) {
            try {
                dispatch({
                    type: DataContextActions.SET_IS_LOADING_JOB_TYPES,
                    status: true,
                });

                const jobTypesResponse = await getJobTypes({
                    status: JobTypesStatus.ENABLED,
                });

                const activeJobTypes = [];
                const jsonSchema = [];
                jobTypesResponse.items.forEach((item, index) => {
                    activeJobTypes.push({
                        id: item?._id || "Unknown id",
                        name: item?.name || "Unknown job type",
                        index,
                    });

                    jsonSchema.push({
                        metadata: item?.metadata || null,
                        value: item?._id || "Unknown id",
                        index,
                    });
                });

                dispatch({
                    type: DataContextActions.SET_JOB_TYPES_DATA,
                    activeJobTypes,
                    jsonSchema,
                });
            } catch (e) {
                console.error(e);
                toast.error(
                    <div>
                        Failed to load job types!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                        <br />
                        If the issue persists, please contact support.
                    </div>
                );
            } finally {
                dispatch({
                    type: DataContextActions.SET_IS_LOADING_JOB_TYPES,
                    status: false,
                });
            }
        }
    }, [dispatch, isAuthorizedForAction]);

    const loadSegments = useCallback(
        async (regionId) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.SEGMENT,
                    AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                        .actions.ListSegments
                )
            ) {
                try {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_SEGMENTS,
                        status: true,
                    });
                    const segmentsResponse = await getAllSegments(regionId);
                    dispatch({
                        type: DataContextActions.SET_SEGMENTS,
                        segments: segmentsResponse || [],
                    });
                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load segments!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                            <br />
                            If the issue persists, please contact support.
                        </div>
                    );
                } finally {
                    dispatch({
                        type: DataContextActions.SET_IS_LOADING_SEGMENTS,
                        status: false,
                    });
                }
            }
        },
        [isAuthorizedForAction, dispatch]
    );

    useEffect(() => {
        if (isAuth && !isRunningSystemInit && userProfileLoadCompleted) {
            loadAllSubTransactions();
            loadJobTypes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, isRunningSystemInit, userProfileLoadCompleted]);

    useEffect(() => {
        if (isAuth && !isRunningSystemInit && userProfileLoadCompleted) {
            //TODO: [MLS-1107] Now all the merchant locations are load in to the data context. Check all the location requests and refer from here (check Member profile specially)
            if (regionId) {
                loadContactAttributes(regionId);
                loadPartnerRewards(regionId);
                requestLoadMerchantLocations({ regionId });
                loadTiers(regionId);
                loadMerchants(regionId);
                loadAffinityGroups(regionId);
                loadCharities(regionId);
                loadIdUsers(regionId);
                loadAllIdUsersAndClients(regionId);
                loadSegments(regionId);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, isRunningSystemInit, regionId, userProfileLoadCompleted]);

    // * This will reload all users and integrations of the current selected region, if there were any changes made in users or integrations.
    useEffect(() => {
        if (regionId && state.areUsersUpdated) {
            loadAllIdUsersAndClients(regionId);
            setAreUsersUpdated(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionId, state.areUsersUpdated]);

    useEffect(() => {
        if (
            isAuth &&
            !isRunningSystemInit &&
            userProfileLoadCompleted &&
            state.merchants?.length !== 0 &&
            !isEmptyObject(state.merchantLocations)
        )
            // * Set merchant locations for filters.
            dispatch({
                type: DataContextActions.SET_FILTERS_RELATED_DATA,
                key: "allMerchantLocationsForDropdown",
                data: getMerchantLocationsForFilters({
                    merchants: state.merchants,
                    merchantLocations: state.merchantLocations,
                }),
            });
    }, [
        isAuth,
        isRunningSystemInit,
        userProfileLoadCompleted,
        state.merchantLocations,
        state.merchants,
    ]);

    const value = useMemo(
        () => ({
            ...state,
            setAreUsersUpdated,
            loadContactAttributes,
            setTier,
            loadTiers,
            loadAffinityGroups,
            requestLoadMerchantLocations,
            refreshMerchantsData,
            loadPartnerRewards,
            loadSegments,
            loadCharities,
        }),
        [
            state,
            setAreUsersUpdated,
            loadAffinityGroups,
            loadContactAttributes,
            loadPartnerRewards,
            loadSegments,
            loadTiers,
            refreshMerchantsData,
            requestLoadMerchantLocations,
            setTier,
            loadCharities,
        ]
    );

    return (
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    );
};

DataContextProvider.propTypes = { children: PropTypes.any };

const DataContextConsumer = DataContext.Consumer;

export {
    DataContext,
    DataContextProvider,
    DataContextConsumer,
    DataContextActions,
};

import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
    DraftJS,
    exportText,
} from "@shoutout-labs/shoutout-message-editor-enterprise";

import { UserContext } from "./userContext";
import { DataContext } from "./dataContext";
import {
    AttributesCategoryGroup,
    CampaignChannels,
    CampaignTypes,
    LaunchDates, PromotionalCampaignFilter,
} from "Data";
import {
    convertEditorStateToHtml,
    createCampaign,
    filterMembers,
} from "Services";
import {
    buildFilterAttributes,
    buildMemberFilterConfig,
    convertTimeFrom12HTo24H,
    getDiffBetween2DatesInMinutes, getMongoDBQuery,
} from "Utils";

const { EditorState } = DraftJS;

const CreateCampaignContext = React.createContext();


const resetCampaignStatus = {
    campaignChannel: "",
    campaignType: [],
    isCreating: "",
    campaignName: "",
    campaignDescription: "",
    selectedProvider: [],
    to: [],
    selectSegments: "ALL",
    subject: "",
    selectLaunchDate: LaunchDates.NOW,
    launchDate: "",
    hours: "00",
    minutes: "00",
    timeOfDay: "",
    smsEditorState: null,
    emailEditorState: null,
    isNavigatedFromOutsideOfCampaignViews: false,
    isLoadingMemberCount: false,
    membersCountForSegmentsOrFilters: 0,
    appliedFilters: [],
};
const initialState = {
    configuredProviders: [],
    filterConfig: {},
    transactionFilterConfig: {},
    ...resetCampaignStatus,
};


const CreateCampaignContextActions = {
    SET_CAMPAIGN_CHANNEL: "setCampaignChannel",
    SET_CAMPAIGN_TYPE: "setCampaignType",
    SET_ATTRIBUTE: "setAttribute",
    SET_TO_SEGMENTS: "setToSegments",
    SET_LAUNCH_DATE: "setLaunchDate",
    SET_EDITOR_STATE: "setEditorState",
    SET_IS_CREATING: "setIsCreating",
    SET_MEMBER_FILTER_CONFIG: "setMemberFilterConfig",
    SET_IS_NAVIGATED_FROM_OUTSIDE: "setIsNavigatedFromOutside",
    SET_APPLIED_FILTERS: "setAppliedFilters",
    RESET: "reset",
};

const reducer = (state, action) => {
    switch (action.type) {
        case CreateCampaignContextActions.SET_CAMPAIGN_CHANNEL: {
            return {
                ...state,
                campaignChannel: action.campaignChannel,
            };
        }
        case CreateCampaignContextActions.SET_CAMPAIGN_TYPE: {
            return {
                ...state,
                campaignType: action.campaignType,
            };
        }
        case CreateCampaignContextActions.SET_MEMBER_FILTER_CONFIG: {
            return {
                ...state,
                filterConfig: action.filterConfig,
            };
        }
        case CreateCampaignContextActions.SET_TRANSACTION_FILTER_CONFIG: {
            return {
                ...state,
                transactionFilterConfig: action.transactionFilterConfig,
            };
        }
        case CreateCampaignContextActions.SET_ATTRIBUTE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case CreateCampaignContextActions.SET_LAUNCH_DATE: {
            return {
                ...state,
                launchDate: action.date,
            };
        }
        case CreateCampaignContextActions.SET_TO_SEGMENTS: {
            return {
                ...state,
                to: action.toSegments,
            };
        }
        case CreateCampaignContextActions.SET_EDITOR_STATE: {
            switch (action.transport) {
                case CampaignChannels.SMS: {
                    return {
                        ...state,
                        smsEditorState: action.editorState,
                    };
                }
                case CampaignChannels.EMAIL: {
                    return {
                        ...state,
                        emailEditorState: action.editorState,
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case CreateCampaignContextActions.SET_IS_CREATING: {
            return {
                ...state,
                isCreating: action.status,
            };
        }
        case CreateCampaignContextActions.SET_IS_NAVIGATED_FROM_OUTSIDE: {
            return {
                ...state,
                isNavigatedFromOutsideOfCampaignViews: action.status,
            };
        }
        case CreateCampaignContextActions.SET_APPLIED_FILTERS: {
            return {
                ...state,
                appliedFilters: action.appliedFilters,
            };
        }
        case CreateCampaignContextActions.RESET: {
            return {
                ...state,
                ...resetCampaignStatus,
            };
        }
        default:
            return state;
    }
};

const getAllConfiguredProviders = (providersList = [], providerType = "") =>
    providersList.map((provider) => ({ ...provider, providerType }));

const validateAndGetLaunchDateTime = (
    payload,
    { selectLaunchDate, launchDate, hours, minutes, timeOfDay },
) => {
    if (selectLaunchDate === LaunchDates.LATER) {
        if (!launchDate) {
            throw new Error("Launch date cannot be empty!");
        }

        if (!hours || !minutes || !timeOfDay) {
            throw new Error("Launch time cannot be empty!");
        }

        const launchDateToDate = new Date(launchDate);
        const timeOfDayIn24H = convertTimeFrom12HTo24H(
            `${hours}:${minutes} ${timeOfDay}`,
        );
        const [hh, mm] = timeOfDayIn24H.split(":");
        const launchDateAndTime = new Date(
            launchDateToDate.getFullYear(),
            launchDateToDate.getMonth(),
            launchDateToDate.getDate(),
            hh,
            mm,
        );
        const launchDateToNowDiff = getDiffBetween2DatesInMinutes(
            launchDateAndTime,
            new Date(),
        );
        if (launchDateToNowDiff < 60) {
            throw new Error(
                `Launch date and time must be at least an hour ahead of the current date and time! Difference: ${launchDateToNowDiff} ${
                    ~[-1, 1].indexOf(launchDateToNowDiff) ? "minute" : "minutes"
                }.`,
            );
        }

        payload = {
            ...payload,
            scheduleOn: launchDateAndTime.toISOString(),
        };
    } else {
        payload = { ...payload };
    }
    return payload;
};

const CreateCampaignContextProvider = (props) => {
    const { regionId, selectedRegion, organization } = useContext(UserContext);
    const {
        contactAttributes,
        tags,
        tiers,
        affinityGroups,
        transactionAttributes,
        merchantLocations,
    } = useContext(DataContext) || {};

    const [state, dispatch] = useReducer(reducer, initialState);

    const setCampaignChannel = useCallback(
        (campaignChannel) =>
            dispatch({
                type: CreateCampaignContextActions.SET_CAMPAIGN_CHANNEL,
                campaignChannel,
            }),
        [dispatch],
    );

    const setCampaignType = useCallback(
        (campaignType) =>
            dispatch({
                type: CreateCampaignContextActions.SET_CAMPAIGN_TYPE,
                campaignType,
            }),
        [dispatch],
    );

    const setSelectedProvider = useCallback(
        (selectedProvider = []) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key: "selectedProvider",
                value: selectedProvider,
            }),
        [],
    );

    const setTransactionFilterConfig = useCallback(
        (transactionFilterConfig) => {
            dispatch({
                type: CreateCampaignContextActions.SET_TRANSACTION_FILTER_CONFIG,
                transactionFilterConfig,
            });
        },
        [dispatch],
    );

    const setMemberFilterConfig = useCallback(
        (filterConfig) => {
            dispatch({
                type: CreateCampaignContextActions.SET_MEMBER_FILTER_CONFIG,
                filterConfig,
            });
        },
        [dispatch],
    );

    const setToSegments = useCallback((toSegments = []) =>
            dispatch({
                type: CreateCampaignContextActions.SET_TO_SEGMENTS,
                toSegments,
            }),
        [dispatch],
    );

    const setAppliedFilters = useCallback((appliedFilters = []) =>
            dispatch({
                type: CreateCampaignContextActions.SET_APPLIED_FILTERS,
                appliedFilters,
            }),
        [dispatch],
    );


    const setMembersCountForSegmentsOrFilters = useCallback(
        (count = 0) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key: "membersCountForSegmentsOrFilters",
                value: count,
            }),
        [dispatch],
    );

    const generateDBQuery = useCallback((filters = []) => {
            let memberFiltersDBQuery = {};
            let transactionFiltersDBQuery = {};
            filters?.forEach((filter) => {
                if (filter.memberFilter) {
                    const memberFilter = getMongoDBQuery(filter?.memberFilter, state?.filterConfig);
                    memberFiltersDBQuery = ({ ...memberFiltersDBQuery, ...memberFilter });
                }
                if (filter.transactionFilter) {
                    const transactionFilter = getMongoDBQuery(filter?.transactionFilter, state?.transactionFilterConfig);
                    transactionFiltersDBQuery = ({ ...transactionFiltersDBQuery, ...transactionFilter });
                }
            });

            return {
                ...(Object.keys(memberFiltersDBQuery)?.length > 0 ? { membersFilter: memberFiltersDBQuery } : {}),
                ...(Object.keys(transactionFiltersDBQuery)?.length > 0 ? { transactionsFilter: transactionFiltersDBQuery } : {}),
            };
        },
        [ state?.filterConfig, state?.transactionFilterConfig],
    );

    const loadMembersCountDataForFiltersOrSegments = useCallback(async (filters = [], throwError = false) => {
            try {
                dispatch({
                    type: CreateCampaignContextActions.SET_ATTRIBUTE,
                    key: "isLoadingMemberCount",
                    value: true,
                });
                if (filters.length === 0)
                    throw new Error("No filters found to load members count!");

                const queryFilters = generateDBQuery(filters);

                if(Object.keys(queryFilters).length===0) {
                    dispatch({
                        type: CreateCampaignContextActions.SET_ATTRIBUTE,
                        key: "isLoadingMemberCount",
                        value: false,
                    });
                    return 0
                }
                const membersCountResponse = await filterMembers(
                    {
                        limit: 25,
                        skip: 0,
                        regionId,
                        projection: ["_id"],
                    },
                    queryFilters,
                );
                dispatch({
                    type: CreateCampaignContextActions.SET_ATTRIBUTE,
                    key: "isLoadingMemberCount",
                    value: false,
                });
                return membersCountResponse.data?.total || 0;
            } catch (e) {
                dispatch({
                    type: CreateCampaignContextActions.SET_ATTRIBUTE,
                    key: "isLoadingMemberCount",
                    value: false,
                });
                console.error(e);
                if (throwError) throw e;
                toast.error(
                    <div>
                        Failed to load member count for the applied filters!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                    </div>,
                );

                return 0;
            }
        },
        [regionId, dispatch, generateDBQuery],
    );

    const setAttribute = useCallback(
        (event) => {
            const key = event.currentTarget.name || "";
            const value = event.currentTarget.value || "";
            if (key === "selectSegments") {
                const updatedFilters= state.campaignType[0]?.value === CampaignTypes.PROMOTIONAL ? [PromotionalCampaignFilter] : []
                setToSegments([]);
                setAppliedFilters(updatedFilters);
                setMembersCountForSegmentsOrFilters(0);
            }
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key,
                value,
            });
        },
        [
            setToSegments,
            setAppliedFilters,
            dispatch,
            setMembersCountForSegmentsOrFilters,
            state.campaignType
        ],
    );

    const setLaunchDate = useCallback(
        (date) =>
            dispatch({
                type: CreateCampaignContextActions.SET_LAUNCH_DATE,
                date,
            }),
        [dispatch],
    );

    const setLaunchTime = useCallback(
        ({ key = "", value = "" }) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key,
                value,
            }),
        [dispatch],
    );

    const onChangeSmsEditor = useCallback(
        (editorState) =>
            dispatch({
                type: CreateCampaignContextActions.SET_EDITOR_STATE,
                editorState,
                transport: CampaignChannels.SMS,
            }),
        [dispatch],
    );

    const onChangeEmailEditor = useCallback(
        (editorState) =>
            dispatch({
                type: CreateCampaignContextActions.SET_EDITOR_STATE,
                editorState,
                transport: CampaignChannels.EMAIL,
            }),
        [dispatch],
    );

    const setIsNavigatedFromOutside = useCallback(
        (status) =>
            dispatch({
                type: CreateCampaignContextActions.SET_IS_NAVIGATED_FROM_OUTSIDE,
                status,
            }),
        [dispatch],
    );

    const reset = useCallback(
        () => dispatch({ type: CreateCampaignContextActions.RESET }),
        [dispatch],
    );

    const onCreateCampaign = useCallback(async (history) => {
        try {
            dispatch({
                type: CreateCampaignContextActions.SET_IS_CREATING,
                status: true,
            });
            const queryFilters = state?.appliedFilters?.map((filter)=>(
                {
                    ...(Object.keys(filter)?.length > 0 ? { memberFilter: filter } : {}),
                    ...(Object.keys(filter)?.length > 0 ? { transactionFilter: filter} : {}),
                }
            ));
            let payload = {
                regionId,
                type: state.campaignType[0]?.value,
                channel: state.campaignChannel,
                name: state.campaignName,
                description: state.campaignDescription,
                senderId: state.selectedProvider[0]?.value,
                segmentIds: state.to.map((t) => t?.value),
                segmentFilters: queryFilters,
            };

            payload = validateAndGetLaunchDateTime(payload, {
                selectLaunchDate: state.selectLaunchDate,
                launchDate: state.launchDate,
                hours: state.hours,
                minutes: state.minutes,
                timeOfDay: state.timeOfDay,
            });

            payload = {
                ...payload,
                message: {
                    messageBody:
                        state.campaignChannel === CampaignChannels.SMS
                            ? exportText(state.smsEditorState)
                            : convertEditorStateToHtml(state.emailEditorState),
                    ...(state.campaignChannel === CampaignChannels.EMAIL
                        ? { messageSubject: state.subject }
                        : {}),
                },
            };

            const createdCampaign = await createCampaign(payload);
            reset();
            toast.success(
                `Successfully created an ${createdCampaign?.channel?.toLowerCase()} ${
                    createdCampaign?.type
                        ? createdCampaign.type.toLowerCase() + " "
                        : ""
                }campaign.`,
            );
            const navigateTo = createdCampaign?.channel || CampaignChannels.SMS;
            history.push(`/campaigns/${navigateTo?.toLowerCase()}`);
        } catch (e) {
            console.error(e);
            toast.error(
                <div>
                    {`Failed to create an ${state.campaignChannel?.toLowerCase()} ${
                        state.campaignType[0]?.value
                            ? state.campaignType[0].value.toLowerCase() + " "
                            : ""
                    }campaign!`}
                    <br />
                    {e.message
                        ? `Error: ${e.message}`
                        : "Please try again later."}
                </div>,
            );
        } finally {
            dispatch({
                type: CreateCampaignContextActions.SET_IS_CREATING,
                status: false,
            });
        }
    }, [
        regionId,
        state.campaignName,
        state.campaignDescription,
        state.campaignType,
        state.to,
        state.campaignChannel,
        state?.appliedFilters,
        state.selectedProvider,
        state.selectLaunchDate,
        state.smsEditorState,
        state.emailEditorState,
        state.subject,
        state.launchDate,
        state.hours,
        state.minutes,
        state.timeOfDay,
        dispatch,
        reset,
    ]);

    useEffect(() => {
        let providerConfigurations = [];

        if (selectedRegion.providerConfiguration) {
            providerConfigurations = [
                ...getAllConfiguredProviders(
                    selectedRegion.providerConfiguration?.emailProvidersList ||
                    [],
                    CampaignChannels.EMAIL,
                ),
                ...getAllConfiguredProviders(
                    selectedRegion.providerConfiguration?.smsProvidersList ||
                    [],
                    CampaignChannels.SMS,
                ),
            ];
        } else if (organization?.configuration?.providerConfiguration) {
            providerConfigurations = [
                ...getAllConfiguredProviders(
                    organization.configuration.providerConfiguration
                        ?.emailProvidersList || [],
                    CampaignChannels.EMAIL,
                ),
                ...getAllConfiguredProviders(
                    organization.configuration.providerConfiguration
                        ?.smsProvidersList || [],
                    CampaignChannels.SMS,
                ),
            ];
        } else providerConfigurations = [];

        dispatch({
            type: CreateCampaignContextActions.SET_ATTRIBUTE,
            key: "configuredProviders",
            value: providerConfigurations
                .filter((pC) => pC?.fromAddress)
                .map((fPC) => ({
                    label: `${fPC.name ? fPC.name + " - " : ""}${
                        fPC.fromAddress
                    }`,
                    value: fPC.fromAddress,
                    providerType: fPC?.providerType || "",
                })),
        });
    }, [
        selectedRegion?.providerConfiguration,
        organization?.configuration?.providerConfiguration,
    ]);


    useEffect(() => {
        if (Object.keys(contactAttributes)?.length > 0) {
            const memberFilterConfig = buildMemberFilterConfig({
                contactAttributes: buildFilterAttributes
                (AttributesCategoryGroup.CONTACT_ATTRIBUTES)
                ({
                    attributes: contactAttributes,
                    merchantLocations,
                    tiers,
                    affinityGroups,
                    selectedRegion,
                }),
                tags: tags || [],
                isLoading: state.isLoading,
            });
            setMemberFilterConfig(memberFilterConfig);
        }
        if (Object.keys(transactionAttributes)?.length > 0) {
            const transactionFilterConfig = buildMemberFilterConfig({
                contactAttributes: buildFilterAttributes
                (AttributesCategoryGroup.TRANSACTION_ATTRIBUTES)
                ({
                    attributes: transactionAttributes,
                    merchantLocations,
                    tiers,
                    affinityGroups,
                    selectedRegion,
                }),
                tags: tags || [],
                isLoading: state.isLoading,
            });
            setTransactionFilterConfig(transactionFilterConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactAttributes, merchantLocations, tiers, affinityGroups, selectedRegion, transactionAttributes, tags, state.isLoading]);


    useEffect(() => {
        onChangeSmsEditor(EditorState.createEmpty());
        onChangeEmailEditor(EditorState.createEmpty());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const value = useMemo(
        () => ({
            ...state,
            setCampaignChannel,
            setCampaignType,
            setSelectedProvider,
            setAttribute,
            setToSegments,
            setLaunchDate,
            setLaunchTime,
            onChangeSmsEditor,
            onChangeEmailEditor,
            setMemberFilterConfig,
            setIsNavigatedFromOutside,
            setAppliedFilters,
            setMembersCountForSegmentsOrFilters,
            reset,
            onCreateCampaign,
            loadMembersCountDataForFiltersOrSegments,
        }),
        [
            state,
            setCampaignChannel,
            setCampaignType,
            setSelectedProvider,
            setAttribute,
            setToSegments,
            setLaunchDate,
            setLaunchTime,
            onChangeSmsEditor,
            onChangeEmailEditor,
            setMemberFilterConfig,
            setIsNavigatedFromOutside,
            setAppliedFilters,
            setMembersCountForSegmentsOrFilters,
            reset,
            onCreateCampaign,
            loadMembersCountDataForFiltersOrSegments,
        ],
    );

    return (
        <CreateCampaignContext.Provider value={value}>
            {props.children}
        </CreateCampaignContext.Provider>
    );
};

CreateCampaignContextProvider.propTypes = { children: PropTypes.any };

export { CreateCampaignContextProvider, CreateCampaignContext };

import { convertStringToCamelCase, toTitleCase } from "../Utils";

const MemberStatus = {
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
    ARCHIVED: "ARCHIVED",
};

const MemberTypes = {
    PRIMARY: "PRIMARY",
    SECONDARY: "SECONDARY",
    CHARITY: "CHARITY",
};

const PreferredContactMethods = {
    Email: "EMAIL",
    Mobile: "MOBILE",
    EmailAndMobile: "EMAIL_AND_MOBILE",
};

const IdentificationTypes = {
    NATIONAL_ID: "NATIONAL_ID",
    DRIVER_LICENSE: "DRIVER_LICENSE",
    PASSPORT: "PASSPORT",
};

const IdTypeSelector = (() => {
    return Object.values(IdentificationTypes).map((iT) => ({
        key: convertStringToCamelCase?.(toTitleCase?.(iT)),
        value: iT,
        label: toTitleCase?.(iT),
    }));
});

const MemberStatusColorCodes = {
    [MemberStatus.ACTIVE]: "success",
    [MemberStatus.SUSPENDED]: "orange",
    [MemberStatus.ARCHIVED]: "danger",
};

const MemberTypesColorCodes = {
    [MemberTypes.PRIMARY]: "primary",
    [MemberTypes.SECONDARY]: "secondary",
    [MemberTypes.CHARITY]: "purple",
};

const IdentificationNumbersLabelColors = {
    [IdentificationTypes.PASSPORT]: "info",
    [IdentificationTypes.DRIVER_LICENSE]: "primary",
    [IdentificationTypes.NATIONAL_ID]: "secondary",
};

const AddPointsTabs = {
    BILL: "BILL",
    POINTS: "POINTS",
};

const MembersContextCaller={
    MEMBERS_VIEW: "MEMBERS_VIEW",
    SEGMENTS_VIEW: "SEGMENTS_VIEW",
}

const MemberReactivateOptions = {
    REACTIVATE_SUSPENDED_CARD: {
        id: "REACTIVATE_SUSPENDED_CARD",
        title: "Reassign a Suspended Card",
    },
    ADD_NEW_CARD: {
        id: "ADD_NEW_CARD",
        title: "Add a New Card",
    },
    NO_CARD: {
        id: "NO_CARD",
        title: "Reactivate the Account without a Card",
    },
};

const AttributesCategoryGroup={
    CONTACT_ATTRIBUTES: "CONTACT_ATTRIBUTES",
    TRANSACTION_ATTRIBUTES: "TRANSACTION_ATTRIBUTES",
}

const RedirectViews={
    MEMBERS_VIEW: "members",
    SEGMENTS_VIEW: "segments",
}

export {
    MemberStatus,
    MemberTypes,
    PreferredContactMethods,
    IdTypeSelector,
    IdentificationNumbersLabelColors,
    AddPointsTabs,
    MemberReactivateOptions,
    IdentificationTypes,
    MemberStatusColorCodes,
    MemberTypesColorCodes,
    MembersContextCaller,
    AttributesCategoryGroup,
    RedirectViews
};

import {
    fetchGet,
    fetchPost,
    fetchGetWithTotal,
    fetchPut,
    jsonToQueryParam,
    fetchPostWithTotal,
    fetchDelete,
    preProcessBodyData,
    fetchPostMultipart,
} from "./CommonServiceUtils";
import { getPermissionPath, generateUserAttribute } from "Utils";
import {
    AccessPermissionModules,
    AccessPermissionModuleNames,
    MemberTypes,
} from "Data";
import Constants from "Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getContactAttributes = (regionId, skip = 0, limit = 1000) => {
    return fetchGet(
        `${BASE_URL}attributes?${jsonToQueryParam({ regionId, skip, limit })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.ATTRIBUTE,
            AccessPermissionModules[AccessPermissionModuleNames.ATTRIBUTE]
                .actions.ListAttributes
        )
    );
};

const createNewUser = (userDetails) => {
    return fetchPost(
        `${BASE_URL}members`,
        preProcessBodyData(userDetails),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .CreateMember
        )
    );
};

const updateMemberById = (id, payload) => {
    return fetchPut(
        `${BASE_URL}members/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .UpdateMember
        )
    );
};

const updateMemberAffinityGroup = (id, payload) => {
    return fetchPut(
        `${BASE_URL}members/affinityGroup/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .UpdateMemberAffinityGroup
        )
    );
};

const getMemberById = (contactId) => {
    return fetchGet(
        `${BASE_URL}members/${contactId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .GetMember
        )
    );
};

const findMembers = (queryObj) => {
    return fetchGet(
        `${BASE_URL}members?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .ListMembers
        )
    );
};

const getMembers = ({
    skip = 0,
    regionId,
    limit,
    searchKey,
    sortDirection,
    sortBy,
    type,
    projection,
    affinityGroupId,
    tierId,
    /* // TODO: Potential future implementation - MemberListFilters. Uncomment when needed.
    affinityGroupJoinedDateFrom,
    affinityGroupJoinedDateTo,
    affinityGroupExpirationDateFrom,
    affinityGroupExpirationDateTo,
    */
}) => {
    return fetchGetWithTotal(
        `${BASE_URL}members?${jsonToQueryParam({
            limit,
            skip: skip,
            regionId,
            ...(sortBy ? { sortBy } : {}),
            ...(sortDirection ? { sortDirection } : {}),
            ...(affinityGroupId ? { affinityGroupId } : {}),
            ...(searchKey ? { searchKey } : {}),
            ...(type ? { type } : {}),
            ...(tierId ? { tierId } : {}),
            /* // TODO: Potential future implementation - MemberListFilters. Uncomment when needed.
            ...(affinityGroupJoinedDateFrom ? { affinityGroupJoinedDateFrom } : {}),
            ...(affinityGroupJoinedDateTo ? { affinityGroupJoinedDateTo } : {}),
            ...(affinityGroupExpirationDateFrom ? { affinityGroupExpirationDateFrom } : {}),
            ...(affinityGroupExpirationDateTo ? { affinityGroupExpirationDateTo } : {}), */
            ...(projection
                ? { fields: generateUserAttribute(projection) }
                : {}),
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .ListMembers
        )
    );
};

const getMembersCount = ({
    regionId,
    searchKey,
    type,
    affinityGroupId,
    tierId,
    /* // TODO: Potential future implementation - MemberListFilters. Uncomment when needed.
    affinityGroupJoinedDateFrom,
    affinityGroupJoinedDateTo,
    affinityGroupExpirationDateFrom,
    affinityGroupExpirationDateTo,
    */
}) => {
    return fetchGet(
        `${BASE_URL}members/count?${jsonToQueryParam({
            regionId,
            ...(affinityGroupId ? { affinityGroupId } : {}),
            ...(searchKey ? { searchKey } : {}),
            ...(type ? { type } : {}),
            ...(tierId ? { tierId } : {}),
            /* // TODO: Potential future implementation - MemberListFilters. Uncomment when needed.
            ...(affinityGroupJoinedDateFrom ? { affinityGroupJoinedDateFrom } : {}),
            ...(affinityGroupJoinedDateTo ? { affinityGroupJoinedDateTo } : {}),
            ...(affinityGroupExpirationDateFrom ? { affinityGroupExpirationDateFrom } : {}),
            ...(affinityGroupExpirationDateTo ? { affinityGroupExpirationDateTo } : {}), */
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .ListMembers
        )
    );
};

const filterMembers = (
    { limit, skip = 0, searchKey, regionId, sortDirection, sortBy, projection },
    filters
) => {
    return fetchPostWithTotal(
        `${BASE_URL}members/filter?${jsonToQueryParam({
            limit,
            skip,
            regionId,
            searchKey,
            sortDirection,
            sortBy,
            fields: generateUserAttribute(projection),
        })}`,
        {
            ...filters
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .ListMembers
        )
    );
};

const changeAccountStatus = (payload) => {
    return fetchPost(
        `${BASE_URL}members/status`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .UpdateMember
        )
    );
};

const getMemberInsights = ({ id, regionId, toDate, fromDate }) => {
    return fetchPostWithTotal(
        `${BASE_URL}members/insight?${jsonToQueryParam({
            id,
            regionId,
            toDate,
            fromDate,
        })}`,
        {},
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .GetMember
        )
    );
};

const getSecondaryAccounts = (queryObj) => {
    queryObj = { ...queryObj, type: MemberTypes.SECONDARY };

    return fetchGet(`${BASE_URL}members?${jsonToQueryParam(queryObj)}`);
};

const getCardConfigurations = (newHeader) => {
    return fetchGet(`${BASE_URL}cards?${jsonToQueryParam(newHeader)}`);
};

const getCardInfoByID = (cardId) => {
    return fetchGet(`${BASE_URL}cards/${cardId}`);
};

const manualGenerateDigitalCards = (payload) => {
    return fetchPost(
        `${BASE_URL}cards/generate`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .GenerateCards
        )
    );
};

const assignCardToMember = (cardDetails) => {
    return fetchPost(
        `${BASE_URL}cards/assign`,
        cardDetails,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .AssignCard
        )
    );
};

const getMemberNotes = (queryObj) => {
    return fetchGet(
        `${BASE_URL}membernotes?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER_NOTE,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER_NOTE]
                .actions.ListMemberNotes
        )
    );
};

const addMemberNotes = (payload) => {
    return fetchPost(
        `${BASE_URL}membernotes`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER_NOTE,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER_NOTE]
                .actions.CreateMemberNote
        )
    );
};

const updateMemberNotes = (id, payload) => {
    return fetchPut(
        `${BASE_URL}membernotes/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER_NOTE,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER_NOTE]
                .actions.UpdateMemberNote
        )
    );
};

const archiveMember = (id) => {
    return fetchDelete(
        `${BASE_URL}members/${id}`,
        null,
        null,
        true
        // TODO: Implement later.
        // getPermissionPath(
        //     AccessPermissionModuleNames.CHARITIES,
        //     AccessPermissionModules[AccessPermissionModuleNames.CHARITIES]
        //         .actions.DeleteCharity
        // )
    );
};

const deleteMemberNotes = (id) => {
    return fetchDelete(
        `${BASE_URL}membernotes/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER_NOTE,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER_NOTE]
                .actions.DeleteMemberNote
        )
    );
};

const changeAccountType = (payload) => {
    return fetchPost(`${BASE_URL}members/type`, payload);
};

const requestAccountSecondary = (payload) => {
    return fetchPost(`${BASE_URL}members/secondaryaddrequest`, payload);
};

const addAccountSecondary = (payload) => {
    return fetchPost(`${BASE_URL}members/secondaryadd`, payload);
};

const requestToConvertPrimary = (payload) => {
    return fetchPost(`${BASE_URL}members/convertprimaryrequest`, payload);
};

const convertToPrimaryAccount = (payload) => {
    return fetchPost(`${BASE_URL}members/convertprimary`, payload);
};

const exportMemberRequest = (payload) => {
    return fetchPost(`${BASE_URL}members/export`, payload);
};

const forgetMember = (payload) => {
    return fetchPost(`${BASE_URL}members/erase`, payload);
};

/* Redemption */

/*Sub Transactions section*/
const getNewSubTransactions = (newHeader) => {
    return fetchGet(
        `${BASE_URL}subtransactiontypes?${jsonToQueryParam(newHeader)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
            AccessPermissionModules[
                AccessPermissionModuleNames.SUB_TRANSACTION_TYPE
            ].actions.ListSubTransactionTypes
        )
    );
};
const deleteSubTransaction = (id) => {
    return fetchDelete(
        `${BASE_URL}subtransactiontypes/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
            AccessPermissionModules[
                AccessPermissionModuleNames.SUB_TRANSACTION_TYPE
            ].actions.DeleteSubTransactionType
        )
    );
};
const editSubTransaction = (id, { name, description, referenceId }) => {
    return fetchPut(
        `${BASE_URL}subtransactiontypes/${id}`,
        {
            name,
            description,
            referenceId,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
            AccessPermissionModules[
                AccessPermissionModuleNames.SUB_TRANSACTION_TYPE
            ].actions.UpdateSubTransactionType
        )
    );
};
const createSubTransaction = (newSubTransactionPayload) => {
    return fetchPost(
        `${BASE_URL}subtransactiontypes`,
        newSubTransactionPayload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
            AccessPermissionModules[
                AccessPermissionModuleNames.SUB_TRANSACTION_TYPE
            ].actions.CreateSubTransactionType
        )
    );
};

const getRegionsConfigurations = (newHeader) => {
    return fetchGet(`${BASE_URL}regions?${jsonToQueryParam(newHeader)}`);
};

const editRegion = ({
    _id,
    regionName,
    defaultCountryISO2Code,
    defaultCurrencyCode,
    regionIconUrl,
    pointConfiguration,
    memberConfiguration,
    notificationConfiguration,
    smsConfiguration,
}) => {
    return fetchPut(`${BASE_URL}regions/${_id}`, {
        regionName,
        defaultCountryISO2Code,
        defaultCurrencyCode,
        regionIconUrl,
        pointConfiguration,
        memberConfiguration,
        notificationConfiguration,
        smsConfiguration,
    });
};

const exportMembers = (payload) => {
    return fetchGet(
        `${BASE_URL}members/export?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .ExportMembers
        )
    );
};

const exportFilterMembers = (
    {
        regionId,
        affinityGroupId,
        sortDirection,
        sortBy,
        projection,
        notificationEmails,
    },
    filters
) => {
    return fetchPost(
        `${BASE_URL}members/filter/export?${jsonToQueryParam({
            regionId,
            affinityGroupId,
            sortDirection,
            sortBy,
            fields: generateUserAttribute(projection),
            notificationEmails,
        })}`,
        {...filters},
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions
                .ExportMembers
        )
    );
};

const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return fetchPostMultipart(`${BASE_URL}imageuploads`, formData);
};

const getSegments = (regionId, limit = 1000, skip = 0) => {
    return fetchGet(
        `${BASE_URL}segments?${jsonToQueryParam({ regionId, skip, limit })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .ListSegments
        )
    );
};

const updateSegment = (id, payload) => {
    return fetchPut(
        `${BASE_URL}segments/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                .actions.UpdateSegment
        )
    );
};

const createSegments = ({ name, regionId, categoryId, memberFilter,transactionFilter }) => {
    return fetchPost(
        `${BASE_URL}segments`,
        { name, regionId, categoryId, memberFilter, transactionFilter },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .CreateSegment
        )
    );
};

const deleteSegment = (id) => {
    return fetchDelete(
        `${BASE_URL}segments/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .DeleteSegment
        )
    );
};

const getSegmentCategories = (regionId, skip = 0, limit = 1000) => {
    return fetchGet(
        `${BASE_URL}segmentcategories?${jsonToQueryParam({
            regionId,
            limit,
            skip,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .ListSegmentCaregories
        )
    );
};

const createSegmentCategory = ({ name, regionId, iconUrl }) => {
    return fetchPost(
        `${BASE_URL}segmentcategories`,
        { name, regionId, iconUrl },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .CreateSegmentCategory
        )
    );
};

// * Retrieve all the segments by applying limit, skip and other args automatically.
const getAllSegments = async (regionId) => {
    const limit = 500;
    let totalCount = 0,
        segments = [];

    try {
        do {
            const segmentsResponse = await getSegments(
                regionId,
                limit,
                segments.length
            );
            totalCount = segmentsResponse.total;
            segments = [...segments, ...segmentsResponse.items];
        } while (segments.length < totalCount);
        return segments;
    } catch (e) {
        return Promise.reject(e);
    }
};

const getSegmentById = async (id) => {
    return fetchGet(
        `${BASE_URL}segments/${id}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .ListSegments
        )
    );
};

export {
    createNewUser,
    updateMemberById,
    getMemberById,
    getMembers,
    changeAccountStatus,
    getSecondaryAccounts,
    filterMembers,
    findMembers,
    getMemberNotes,
    addMemberNotes,
    updateMemberNotes,
    deleteMemberNotes,
    changeAccountType,
    getCardConfigurations,
    getCardInfoByID,
    assignCardToMember,
    getNewSubTransactions,
    deleteSubTransaction,
    editSubTransaction,
    createSubTransaction,
    getRegionsConfigurations,
    editRegion,
    requestAccountSecondary,
    addAccountSecondary,
    requestToConvertPrimary,
    convertToPrimaryAccount,
    exportMemberRequest,
    forgetMember,
    uploadImage,
    archiveMember,
    exportMembers,
    exportFilterMembers,
    updateMemberAffinityGroup,
    getMemberInsights,
    getMembersCount,
    getSegments,
    createSegments,
    deleteSegment,
    getAllSegments,
    getSegmentCategories,
    createSegmentCategory,
    manualGenerateDigitalCards,
    getContactAttributes,
    getSegmentById,
    updateSegment
};

import { toTitleCase } from "Utils";

const CampaignChannels = {
    SMS: "SMS",
    EMAIL: "EMAIL",
};

const CampaignTypes = {
    PROMOTIONAL: "PROMOTIONAL",
    TRANSACTIONAL: "TRANSACTIONAL",
};

const CampaignStatus = {
    CREATING: "CREATING",
    RUNNING: "RUNNING",
    FINISHED: "FINISHED",
    LISTENING: "LISTENING",
    FAILED: "FAILED",
    SCHEDULED: "SCHEDULED",
    PAUSED: "PAUSED",
    WAITING: "WAITING",
};

const CampaignsFilterOptions = {
    STATUS: "STATUS",
    TO_SMS: "TO_SMS",
    TO_EMAIL: "TO_EMAIL",
};

const CampaignStatusOptions = Object.keys(CampaignStatus).map((status) => ({
    label: toTitleCase(status),
    value: status,
}));

const LaunchDates = {
    NOW: "NOW",
    LATER: "LATER",
};

const ObjectKeyMap = {
    SMS: "smsHeaders",
    EMAIL: "emailHeaders",
};

const CampaignDetailsViewMainHeader = {
    SMS: "SMS Campaigns Details",
    EMAIL: "Email Campaigns Details",
};

const CampaignDetailsSectionTwo = {
    emailHeaders: {
        totalMessages: {
            label: "Total Messages",
            nextPointArrow: true,
            isFirstStep: true,
        },
        successCount: {
            label: "Submitted Messages",
            nextPointArrow: true,
            percentageKey: "submittedPercentage",
            extraRowKey: "failedToSubmit",
        },
        deliveredMessages: {
            label: "Delivered Messages",
            nextPointArrow: true,
            percentageKey: "openedPercentage",
            extraRowKey: "bounce",
        },
        openedMessages: {
            label: "Opened Messages",
            nextPointArrow: true,
            percentageKey: "clickedPercentage",
            extraRowKey: "spam",
        },
        clickedMessages: { label: "Clicked Messages", nextPointArrow: false },
    },
    smsHeaders: {
        totalMessages: {
            label: "Total Messages",
            nextPointArrow: true,
            isFirstStep: true,
        },
        successCount: {
            label: "Submitted Messages",
            nextPointArrow: false,
            percentageKey: "submittedPercentage",
            extraRowKey: "failedToSubmit",
        },
        // TODO: REMOVE IF NOT NEEDED.
        // deliveredMessages: {
        //     label: "Delivered Messages",
        //     nextPointArrow: true,
        //     percentageKey: "clickedPercentage",
        // },
        // clickedMessages: { label: "Clicked Messages", nextPointArrow: false },
    },
};

const CampaignDetailsSectionTwoColumnSize = {
    EMAIL: "flex-container-div-col-2",
    SMS: "flex-container-div-col-3",
};

const MarketingConsentFilter ={
    id: "notificationPreference.allowPromotionalNotifications",
    type: "group",
    disabled: true,
    children1: {
        "bab88ba9-0123-4456-b89a-b193d04e05ee": {
            "type": "rule",
            "properties": {
                "field": "notificationPreference.allowPromotionalNotifications",
                "operator": "equal",
                "value": [
                    "true"
                ],
                "valueSrc": [
                    "value"
                ],
                "valueError": [
                    null
                ],
                "valueType": [
                    "text"
                ]
            }
        }
    }
};

const PromotionalCampaignFilter = {
    id:"marketing_consent_filter",
    segment: {
        id: "custom_filter",
        name: "Marketing/Promotional Consented Members",
        isCustomFilter: true,
    },
    memberFilter: MarketingConsentFilter,
};

const PromotionalCampaignMsg = (
    <>
        {"Please note that all "}
        <span className="font-weight-bold">
            {`${toTitleCase(CampaignTypes.PROMOTIONAL)} Campaigns`}
        </span>
        {" will "}
        <span className="font-weight-bold">ONLY</span>
        {" be sent to "}
        <span className="font-weight-bold">Loyalty Members</span>
        {
            " who have given their consent to receive marketing and/or promotional material, regardless of any selected segments or applied filters."
        }
    </>
);

export {
    CampaignChannels,
    CampaignStatus,
    CampaignsFilterOptions,
    CampaignStatusOptions,
    LaunchDates,
    ObjectKeyMap,
    CampaignDetailsViewMainHeader,
    CampaignDetailsSectionTwo,
    CampaignDetailsSectionTwoColumnSize,
    CampaignTypes,
    MarketingConsentFilter,
    PromotionalCampaignFilter,
    PromotionalCampaignMsg,
};
